import React from 'react';
import styles from './index.module.scss';

export default function ImgButton({ href = '#', src, hoverSrc, children }) {
    return (
        <a href={href} className={styles.root}>
            <img className={styles.default} src={src} alt={children} />
            <img className={styles.hover} src={hoverSrc} alt={children} />
        </a>
    );
}
