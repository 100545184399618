import ArrowButton from '../components/arrow-button';
import Layout from '../components/layout';
import React, { useState } from 'react';
import SEO from '../components/seo';
import ImgButton from '../components/img-button';

export default function MerchPage() {
    const [darkMode, setDarkMode] = useState(1);
    const [audio, setAudio] = useState(1);
    const sw = {
        setDarkMode,
        setAudio,
        values: { darkMode, audio }
    };
    return (
        <Layout sw={sw} fullWidth>
            <SEO title="merch" />
            <ArrowButton variant="back" href="/" fixed>
                Home
            </ArrowButton>
            <div style={{ padding: '1rem' }}>
                <ImgButton
                    href="https://instagram.com/nocaptcha.live"
                    src="/assets/images/cd_front.png"
                    hoverSrc="/assets/images/cd_back.png"
                >
                    22222 CD
                </ImgButton>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <h1 style={{ padding: 2, color: 'rgb(var(--green))' }}>
                        $6.66
                    </h1>
                    <h2 style={{ color: 'rgb(var(--body-txt)' }}>
                        DM @nocaptcha.live on IG to pre-order !
                    </h2>
                    <a href="https://randomgreasyboy.bandcamp.com/">bandcamp</a>
                </div>
            </div>
        </Layout>
    );
}
